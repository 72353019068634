import * as React from "react"
import { graphql } from 'gatsby';

import Layout from "../components/layout"
import Seo from "../components/seo"

// The bridge for Gatsby Background Image in V3
import { BgImage } from 'gbimage-bridge'

// All the gatsby-plugin-image goodness
import { StaticImage, getImage } from "gatsby-plugin-image"

import { Row, Col, Container } from "react-bootstrap"

const SecondPage = ({data}) => (
  <Layout>
    <BgImage 
      image={getImage(data.indexImage)} 
      className="masthead"
    >
       <div className="black-overlay">
            <div className="content-box">
              <h1>Amenities</h1>
            </div>
          </div>
    </BgImage>
    <Container>
          <h2 className="summary">All-Inclusive Ease</h2>
          <p className="summary">The Wick is more than just an office – it's an all-inclusive office experience. Rental rates are inclusive of all utilities so you can focus on your work and let us handle the bills. In addition,  we offer some of these other great amenities to members as well, all included in your lease.
          </p>
          <hr />
        </Container>

        <Container>

          <Row>
            <Col lg={4}>
              <p>
                <StaticImage
                  src="../images/no-invoices.jpg"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="No invoices"
                />
              </p>
                {/* <p><Img fluid={props.data.noInvoices.childImageSharp.fluid} /></p> */}
            </Col>
            <Col lg={8} className="singleCol">
              <h2>Utilities Included</h2>
              <p><strong>Electricity, WI-FI, Water, Security, Trash, Maintenance</strong></p>

              <p>The Wick is more than just an office – it's an all-inclusive office experience. Rental rates are inclusive of all utilities so you can focus on your work and let us handle the bills. In addition,  we offer some of these other great amenities to members as well, all included in your lease.</p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p>
                <StaticImage
                  src="../images/conference-room.jpg"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="Conference Room"
                />
              </p>
            </Col>
            <Col lg={8} className="singleCol">
              <h2>Conference and Meeting Rooms</h2>
              <p><strong>Executive conference and meeting rooms available for use</strong></p>

              <p>All tenants have access to our 14 person executive conference room and 24 person convertible meeting space on a first-come first-serve basis. Reservations are available to tenants at no additional charge.</p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p>
                <StaticImage
                  src="../images/events.jpg"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="Events"
                />
              </p>
                {/* <p><Img fluid={props.data.events.childImageSharp.fluid} /></p> */}
            </Col>
            <Col lg={8} className="singleCol">
              <h2>Community Events</h2>
              <p><strong>Regular Tenant Networking Events Throughout The Year</strong></p>

              <p>The most important part of The Wick is the community of driven, passionate people in a variety of industries. We coordinate tenant events regularly to provide opportunities for tenants to meet, mingle, and work together.</p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p>
                <StaticImage
                  src="../images/machine-clipart-office-equipment-9.jpg"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="Office Equipment"
                />
              </p>
                {/* <p><Img fluid={props.data.officeEquipment.childImageSharp.fluid} /></p> */}
            </Col>
            <Col lg={8} className="singleCol">
              <h2>Print/Copy/Scan</h2>
              <p><strong>Multipurpose Copy Center</strong></p>

              <p>All properties have their own high speed professional grade multi-purpose printer/copiers with scan to email capability.</p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p>
                <StaticImage
                  src="../images/bullet-camera-atlanta-business.jpg"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="Camera"
                />
              </p>
                {/* <p><Img fluid={props.data.camera.childImageSharp.fluid} /></p> */}
            </Col>
            <Col lg={8} className="singleCol">
              <h2>24/7 Access & Security</h2>
              <p><strong>Secure Buildings with 24/7 Access</strong></p>

              <p>All properties have 24/7 security with cameras, automatic door locks, and more. Tenants have 24/7 access with unique security credentials for after hour access.</p>
            </Col>
          </Row>
          

        </Container>
  </Layout>
)

export const Head = () => <Seo title="Amenities" />

export default SecondPage

export const query = graphql`
  query {
    indexImage: file(relativePath: {eq: "amenities.jpg"}) {
      id
      base
      relativePath
      childImageSharp {
        gatsbyImageData(
          width: 2000
          webpOptions: {quality: 30}
          placeholder: BLURRED
          blurredOptions: {width: 30}
        )
      }
    }
  }
`